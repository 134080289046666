const fetchJson = (url, options) => (
    fetch(url, options)
    .then( res => res.json())
    .then( res => {
        return new Promise( (resF, errF) => {
            if(res.successResponse) {
                resF(res.successResponse)
            } else {
                if(res.errorResponse) {
                    errF(res.errorResponse)
                } else {
                    errF({ code: "BAD_ERROR_RESPONSE", message: res})
                }
            }
        })
    })
)

export { fetchJson }