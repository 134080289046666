import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { queryCache } from 'react-query'

const AuthStateContext = React.createContext()
const AuthActionContext = React.createContext() 

function AuthProvider({dataProvider, children, ...props}) {
    const isLoggedInQ = useQuery(
        'checkLoggedIn', 
        (key) => dataProvider.loginCheck(),
        {
            refetchOnWindowFocus: false,
        }
    )

    const [loginMutate, loginMutateState ] = useMutation(
        (v) => dataProvider.loginWithCredentials(v),
        {
            onSuccess: (data) => queryCache.setQueryData('checkLoggedIn', data)
        }
    )

    const [logoutMutate, logoutMutateState ] = useMutation(
        (v) => dataProvider.logout(),
        {
            onSuccess: (data) => queryCache.setQueryData('checkLoggedIn', {code: "OK", payload: { loggedUser: null }}),
        }
    )



    const fn = {
        handleLogin: ({username, password}) => loginMutate({username, password}),
        handleLogout: () => logoutMutate(),
    }

    const user = (isLoggedInQ.data && isLoggedInQ.data.code === 'OK' && isLoggedInQ.data.payload.loggedUser) ? isLoggedInQ.data.payload.loggedUser : null
    const isFetching = isLoggedInQ.isFetching || loginMutateState.status === 'loading' || logoutMutateState.status === 'loading' || false
    const error = isLoggedInQ.error || loginMutateState.error || logoutMutateState.error || null

    return (
        <AuthStateContext.Provider value={{user, error, isFetching, dataProvider}}>
            <AuthActionContext.Provider value={fn}>
                { children }
            </AuthActionContext.Provider>
        </AuthStateContext.Provider>
    )
}

function useAuthState() {
    const context = React.useContext(AuthStateContext)
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider')
    }
    return context
}

function useAuthAction() {
    const context = React.useContext(AuthActionContext)
    if (context === undefined) {
        throw new Error('useAuthAction must be used within a AuthProvider')
    }
    return context
}

const useAuth = () => [useAuthState(), useAuthAction()]

export { AuthProvider, useAuthState, useAuthAction, useAuth }