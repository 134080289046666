import React, { Suspense } from 'react'
import { ReactQueryDevtools } from 'react-query-devtools'

import './App.css'

import { AuthProvider, useAuthState } from './components/auth/AuthContext'
import AuthDataProvider from './api/authDataProvider'


const AuthenticatedApp = React.lazy(() => import('./screens/AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./screens/UnauthenticatedApp'))

function AuthenticatedOrNot() {
  const authState = useAuthState()
//  console.log(auth)
  return (
    <> {
        <Suspense fallback={<div>Loading...</div>}>
          {authState.error === null && authState.user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </Suspense>
    }
    </>
  )
}

function App() {
  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider dataProvider={AuthDataProvider(process.env.REACT_APP_API_URL)}>
        <div className="App">
        <AuthenticatedOrNot />
        </div>
      </AuthProvider>
    </>
  )
}

export default App

/*function App() {
  /*const [chartData, setChartData] = useState([]);
  let value = 100;
  let inc = 10;

  chartData.push({time: new Date(2019,0,1,1,1,0).getTime()/1000, value: value});

  function onDateRangeChange(a) {
    console.log(a);
    let o = [];
    for(let m = 0; m<1; m++) {
      for(let d = 1; d<28; d++) {
        for(let h = 0; h<24; h++) {
          if(d === 3) inc = 1; else inc = 10;
          for(let i = 0; i<60; i+=inc) {
            value += Math.random() - 0.5;
            o.push({time: new Date(2019,m,d,h,i,0).getTime()/1000, value: value});
          }
        }
      }
    }
    console.log(o);
  }*/
/*
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    axios.get(`http://localhost:8888/api/v1/login.php`, {withCredentials: true}).then(response => {
      setUserSession(response.data.successResponse.payload.loggedUser);
      setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      setAuthLoading(false);
    });
  }, []);

  if (authLoading) {
    return <div className="content">Checking Authentication...</div>
  }

    const user = useUser()
    return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}


/*
  );
*/