import { commonOptions } from './common'
import { fetchJson } from './fetchJson'


export default (apiUrl, httpClient = fetchJson) => ({

    loginWithCredentials: ({username, password}) => {
        return httpClient(apiUrl + "api/v1/login.php", 
        {
            ...commonOptions,
            method: 'POST',
            body: JSON.stringify( { username, password } ),
        })
    },

    requestResetPassword: ({username, email, link}) => {
        return httpClient(apiUrl + "api/v1/requestPasswordReset.php",
        {
            ...commonOptions,
            method: 'POST',
            body: JSON.stringify( { username, email, link } ),
        })
    },   

    checkResetPasswordToken: ({token}) => {
        return httpClient(apiUrl + "api/v1/resetPassword.php?token=" + token,
        {
            ...commonOptions,
            method: 'GET',
        })        
    },    

    resetPassword: ({username, password, token}) => {
        return httpClient(apiUrl + "api/v1/resetPassword.php",
        {
            ...commonOptions,
            method: 'POST',
            body: JSON.stringify( { username, password, token} ),
        })
    },   

    loginCheck: () => {
        return httpClient(apiUrl + "api/v1/checkLoggedIn.php", 
        {
            ...commonOptions,
            method: 'GET',
        })
    },

    logout: () => {
        return httpClient(apiUrl + "api/v1/logout.php", 
        {
            ...commonOptions,
            method: 'GET',
        })
    }

})
